@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Share+Tech&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body
{
    width: 100%;
    font-family: 'Share Tech', sans-serif;
    background-color: #141414;
    letter-spacing: 0.1em;
    padding: 0;
    margin: 0;
}

.load_screen
{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.load_screen2
{
    width: 100%;
    height: auto;
    color: #fff;
    text-align: center;
    margin-top: 200px;
}

.err_screen
{
    width: 100%;
    height: auto;
    color: #fff;
    text-align: center;
}

span
{
    font-family: 'Arial' !important;
}

button
{
    font-family: 'Share Tech', sans-serif;
    font-size: 16px;
}

h1, p
{
    color: #fff;
}

.section_titles
{
    font-size: 30px;
}

.nav
{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    height: 80px;
    background-color: rgba(20, 20, 20, 0.5);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}


/* LOGO */
.logo, .logo_min
{
	float: left;
	margin-left: 10%;
	position: relative;
	cursor: pointer;
}

.logo_min
{
	display: none;
}

.logo img
{
	width: 190px;
	margin-top: 16px;
	height: auto;
	position: relative;
}

.logo_min img
{
	width: 34px;
	margin-top: 14px;
	height: auto;
	position: relative;
}

/* MENU */
.menu
{
	float: right;
	margin-right: 10%;
	margin-top: 32px;
}

.top_link
{
	margin-left: 30px;
	font-weight: normal;
	text-decoration: none;
    color: #b2bcaf;
    cursor: pointer;
    font-size: 16px;
}

.top_link:hover
{
    color:#fff;
}

.menu_selected
{
    border-bottom: 4px solid #b2bcaf;
    color:#fff;
}


/*FOOTER*/
.close
{
    width: 100%;
    position: relative;
    z-index: 1000;
    text-align: center;
    color: #fff;
}

.close p
{
    font-size: 15px;
}

.foot
{
    position: fixed;
    bottom:20px;
    width: 100%;
    z-index: 1000;
}

.clear
{
    clear: both;
}

/*SUBMENU*/
.title
{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.subnav
{
    width: 80%;
    margin: 0 auto;
}

.subbtn
{
    text-align: center;
    width: calc(47% - 20px);
    display: block;
    float: left;
    color: #b2bcaf;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: calc(10px + 1.5%);
    margin-right: calc(10px + 1.5%);
    background: transparent;
    border: none !important;
    cursor: pointer;
}

.subbtn:hover
{
    color: #fff;
}

.sub_selected
{
    color: #fff;
    border-bottom: 5px solid #fff !important;
}

/*HOME*/
.cover
{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    background-color: #141414;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.cover_info
{
    width: 80%;
    text-align: center;
    position: relative;
    z-index: 300;
}

.covertitle
{
    margin-bottom: 30px;
    font-size: 30px;
}

.coverproject
{
    font-size: 18px;
    margin-bottom: 20px !important;
}

.actionbtn
{
    text-decoration: none;
    color: #141414;
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
}

.actionbtn:hover
{
    color: #fff;
    background-color: #141414;
}

.cover_preview
{
    margin-top: 80px;
}


/*PAGES*/
.popup
{
    width: 100%;
    height:100%;
    position: fixed;
    top: 0;
    left:0;
    background-color: rgba(20, 20, 20, 0.8);
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}

.closepop
{
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    font-size: 50px;
    cursor: pointer;
}

.popImg
{
    width: 80%;
    max-width: 800px;
    height: auto;
}

.popImg img
{
    width: 100%;
    height: auto;
}

.popVid
{
    width: 50%;
    max-width: 600px;
    height: auto;
}

.popframe
{
    padding: 100% 0 0 0;
    position:relative;
}

.popframe iframe
{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.container
{
    width: 100%;
    margin-top: 120px;
}

.work_links
{
    width: 100%;
    height: calc(100% - 200px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work_main
{
    width: 70%;
    text-align: center;
    margin-top: -10%;
}

.work_boxes
{
    width: 100%;
}

.work_link
{
    width: calc(33.33% - 22px);
    height: 10vh;
    padding: 60px 10px;
    margin: 1px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-size: 2.2vw;
}

.work_link:hover
{
    background-color: #fff;
    color: #141414;
}

.main_list
{
    width: 80%;
    max-width: 900px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 50px;
}

#motion_list
{
    display: block;
}

#inter_list
{
    display:none;
}

.pro_main
{
    width: 50%;
    height: auto;
    float: left;
}

.pro_margin
{
    width: calc(94%-20px);
    padding: 3%;
    margin: 10px; 
}

.pro_image
{
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.pro_image2
{
    width: 100%;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
}

.pro_image2:hover
{
    opacity: .6;
}

.pro_title
{
    margin: 0;
    padding: 0;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pro_client
{
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin-top: -5px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pro_link
{
    text-decoration: none;
    color: #141414;
    background-color: #fff;
    padding: 5px;
}

.pro_link2
{
    text-decoration: none;
    color: #141414;
    background-color: #fff;
    padding: 5px;
    opacity: .1;
    pointer-events: none;
}

.pro_link:hover
{
    background-color: #b2bcaf;;
}

/**/
.project_main
{
    width: 100%;
    height: auto;
}

.back_video
{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top:0;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    overflow: hidden;
    z-index: 100;
}

.filtercolor
{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top:0;
    background-color:rgba(20, 20, 20, .9);
    z-index: 200;
}

.filtercolor2
{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top:0;
    background-image:url(/static/media/ui.c922f1bb.png);
    z-index: 200;
    background-size: cover;
    background-position: center;
}

.project
{
    width: 80%;
    margin: 0 auto;
    height: auto;
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
    z-index: 300;
}

.frame
{
    padding: 56.25% 0 0 0;
    position:relative;
}

.frame iframe
{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.project h1
{
    color:#fff;
    font-size: 24px;
}

.project p
{
    color:#fff;
    font-size: 18px;
}

.backbtn
{
    text-decoration: none;
    color: #b2bcaf;
    margin-bottom: 20px !important;
    display:inline-block;
    font-size: 16px;
}

.backbtn:hover
{
    color:#fff;
}

/*CONTACT*/
.info_contact
{
    width: 90%;
	height: auto;
	text-align: center;
}

.info_contact p
{
    font-size: 18px;
}

.linkmail
{
    font-size: 18px;
    color:#fff;
    display: block;
    margin-bottom: 20px;
    text-decoration: none;
    opacity: 1;
}

.linkmail:hover
{
    opacity: .5;
}

.info_contact .redes img
{
	width: 30px;
	height: auto;
	margin: 5px;
	opacity: .5;
}

.info_contact .redes img:hover
{
	opacity: 1;
}

/* RESPONSIVE  */
@media screen and (min-width:1200px){
	
	.project
	{
		width: 60%;
	}

}

@media screen and (max-width:600px){
    
    .logo
	{
		display: none;
	}

	.logo_min
	{
		display: block;
    }
    
    .pro_main
    {
        width: 100%;

    }

    .pro_margin
    {
        width: 100%; 
        padding: 0;
        margin-bottom: 30px;
        margin-left: 0;
    }

    .subbtn
    {
        width: 50%;
        float: left;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .work_link
    {
        width: calc(100% - 22px);
        height: 5vh;
        padding: 20px 10px;
        font-size: 22px;
    }

    .popVid
    {
        width: 80%;
        max-width: 800px;
        height: auto;
    }

    

}

@media screen and (max-width:400px){
    
    .top_link
    {
        margin-left: 20px;
    }

    .covertitle
    {
        margin-bottom: 30px;
        font-size: 24px;
    }
   
}

@media screen and (max-width:320px){
    
    .top_link
    {
        margin-left: 15px;
        font-size: 15px;
    }
   
}





